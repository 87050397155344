import axios from 'axios'
import i18n from '@/lang/index.js'

import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.state.user.token) {
      //设置header头
      config.headers['Authorization'] = 'Bearer ' + store.state.user.token
    }

    // 根据当前语言环境添加website参数
    const locale = i18n.locale.toLowerCase()

    // console.log('config1',locale,config)
    if (locale === 'en') {
      config.params = { ...config.params, webType: 2 }
    } else if (locale === 'zh') {
      config.params = { ...config.params, webType: 1 }
    }
    
    // console.log('config2',config)
    return config
  },
  error => {
    return Promise.reject(error)
  }

)



// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    // console.log('loginRequest', res)
    // alert(1)
    if (res.code === 401) {
      // window.location.href = process.env.VUE_APP_LOGIN_URL + '?url=' + window.btoa(unescape(encodeURIComponent(window.location)))
      return
    }

    if (res.code !== 1 && res.code !== 1001) {

      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }

    return res
  },
  error => {
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service