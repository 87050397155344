<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1">Start retrieving your password.</strong>
      <div class="menu">
        <div class="alink" @click="tologin">Login</div>
        <div class="alink" @click="toRegister">Register</div>
      </div>
    </div>

    <div class="container mt-5 mb-5">
      <div class="forget">
        <div class="form-row area-one p-4">
          <form class="ajax-form" method="post">
            <div class="jumbotron psrel mb-4">
              <h4 class="fs-4">{{ $t("Retrieve password") }}</h4>
              <hr class="my-4" />
            </div>

            <div class="input-group mb-4">
              <span class="input-group-text">{{ $t("user") }}</span>
              <input
                type="text"
                class="email form-control ajax_username"
                name="username"
                ref="username"
                v-model="form.username"
                :disabled="countdown > 0"
                @blur="validateInput"
                form-verify="required|email"
              />
              <a
                :class="{
                  btn: true,
                  'btn-primary': true,
                  'ajax-sms': true,
                  hasClicked: countdown > 0,
                }"
                @click="sendVerifyCode"
                status="0"
                >{{ countdown === 0 ? $t("getcode") : `Resend (${countdown})` }}
              </a>
              <p class="feedback ps-2">{{ $t("usertip") }}</p>
            </div>

            <div class="input-group mb-4 htmlCaptcha">
              <span class="input-group-text">{{ $t("Captcha") }}</span>
              <input
                type="text"
                class="form-control ajax_forget_vcode"
                v-model="form.verify_code"
                @blur="validateInput"
                name="captcha"
                ref="captcha"
                form-verify="required"
              />
              <p class="feedback">{{ $t("CaptchaTip") }}</p>
            </div>

            <div class="input-group mb-4">
              <span class="input-group-text">{{ $t("newPassword") }}</span>
              <input
                type="password"
                class="form-control ajax_password"
                v-model="form.password"
                @blur="validateInput"
                name="password"
                ref="password"
                form-verify="required|stringCheck"
              />
              <p class="feedback ps-2">{{ $t("newPasswordTip") }}</p>
            </div>

            <div class="input-group mb-4">
              <span class="input-group-text">{{
                $t("ConfirmNewPassword")
              }}</span>
              <input
                type="password"
                class="form-control ajax_password2"
                v-model="form.password2"
                @blur="validateInput"
                name="password2"
                ref="password2"
                form-verify="required|stringCheck"
              />
              <p class="feedback ps-2">{{ $t("ConfirmNewPasswordTip") }}</p>
            </div>

            <div class="d-flex flex-row align-items-center">
              <span class="pe-4 ps-0"
                ><button
                  type="button"
                  @click="toRetrieve"
                  class="btn btn-danger ajax-submit"
                >
                  {{ $t("Submit") }}
                </button></span
              >
            </div>
          </form>
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import Foot from "@/components/website/Foot.vue";
import { retrieve, sendVerifyCode } from "@/api/login.js";

export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {
      countdown: 0, // 初始化倒计时为0
      form: {
        clientType: 1,
        allowNote: false,
        username: "",
        password: "",
        password2: "",
        verify_code: "",
      },

      ruleField: {
        1: ["username", "password", "captcha"],
        2: ["username", "password", "captcha"],
        3: ["username", "password", "captcha"],
      },

      fieldErrors: {
        username: "",
        password: "",
        captcha: "",
      }, // 存储每个字段的错误信息

      search: "",
    };
  },

  computed: {
    isZh: {
      get() {
        return this.$i18n.locale === "zh";
      },
    },
  },

  mounted() {},
  methods: {
    toRegister() {
      this.$router.push("/register");
    },

    startCountdown() {
      if (this.countdown !== 0) {
        return; // 如果已经开始了倒计时则不再进行操作
      }

      this.countdown = 60; // 设置倒计时为60秒

      const timer = setInterval(() => {
        this.countdown--; // 每次间隔1秒将倒计时数量减少1

        if (this.countdown <= 0) {
          clearInterval(timer); // 当倒计时结束后清除定时器
        }
      }, 1000);
    },

    sendVerifyCode() {
      if (this.validateSingle("username", ["required", "email"], false)) {
        this.startCountdown();
        sendVerifyCode({ username: this.form.username, type: 2 }).then((res) => {
          this.$message({
            message: res.message,
            type: "success",
          });
        });
      } else {
        this.$message({
          message: this.$t("verify-email"),
          type: "warning",
        });
      }
    },

    validateInput(event) {
      const field = event.target.getAttribute("name");
      let strings = event.target.getAttribute("form-verify");
      const rules = strings.split("|");

      this.validateSingle(field, rules, event);
    },

    validateSingle(field, rules, event) {
      let pass = true;
      this.fieldErrors[field];

      for (const rule of rules) {
        if (!this.validateField(field, rule)) {
          this.fieldErrors[field] = this.$t(`verify-${rule}`);
          pass = false;
        } else {
          delete this.fieldErrors[field];
        }
      }
      if (event) {
        // 更新界面反馈
        // 示例：在DOM中找到对应的反馈元素并设置其内容
        const feedbackElement =
          event.target.parentNode.querySelector(".feedback");
        if (feedbackElement.classList.contains("feedback")) {
          feedbackElement.textContent = this.fieldErrors[field] || "";
          // feedbackElement
          if (this.fieldErrors[field]) {
            feedbackElement.style.color = "red";
          } else {
            feedbackElement.style.color = ""; // 恢复到默认颜色
          }
        }
      } else {
        return pass;
      }
    },

    validateField(field, rule) {
      // 根据rule执行相应的验证逻辑，并返回验证结果（true或false）
      // 示例：
      switch (rule) {
        case "required":
          return this.form[field] !== "";
          break;
        case "email":
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const phoneRegex = /^1[3-9]\d{9}$/; // 示例为中国手机号正则表达式
          if (this.isZh) {
            return phoneRegex.test(this.form[field]);
          } else {
            return pattern.test(this.form[field]);
          }

          break;
        case "stringCheck": // 假设这是一个自定义字符串检查规则
          return (
            typeof this.form[field] === "string" &&
            this.form[field].length > 0 &&
            this.form[field] === this.form.password
          );
          break;
      }
    },
    clientTypeChange(option) {
      // console.log('change', this.form.clientType)
    },
    toRetrieve() {
      //整体验证
      if (this.allFieldsVerify()) {
        const params = {
          username: this.form.username,
          verify_code: this.form.verify_code,
          password: this.form.password,
        };
        retrieve(params).then((res) => {
          this.$router.push("/login");
        });
      } else {
        // console.log('error')
      }
    },

    allFieldsVerify() {
      let pass = true;
      this.ruleField[this.form.clientType].map((item) => {
        let strings = this.$refs[item].getAttribute("form-verify");
        const rules = strings.split("|");

        // console.log('rules', rules, 'item', item)

        for (const rule of rules) {
          if (!this.validateField(item, rule)) {
            pass = false;
            this.fieldErrors[item] = this.$t(`verify-${rule}`);
            break;
          } else {
            delete this.fieldErrors[item];
          }
        }
        // 更新界面反馈
        // 示例：在DOM中找到对应的反馈元素并设置其内容
        const feedbackElement =
          this.$refs[item].parentNode.querySelector(".feedback");
        if (feedbackElement.classList.contains("feedback")) {
          feedbackElement.textContent = this.fieldErrors[item] || "";
          if (this.fieldErrors[item]) {
            feedbackElement.style.color = "red";
          } else {
            feedbackElement.style.color = ""; // 恢复到默认颜色
          }
        }
      });
      return pass;
    },

    tologin() {
      this.$router.push("/login");
    },

    //跳到外链
    towindowurl() {
      window.location.href = this.banner.link;
    },

    init() {
      // alert(this.site.id)
      detail({ site_id: this.site.id }).then((res) => {
        this.banner = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.forget {
  min-height: 600px;
  display: flex;
  align-items: center;
  background-image: url("/svg/member-forget.svg");
  background-repeat: no-repeat;
  background-position: right center;

  form {
    width:72%;
    border: 1px solid var(--el-border-color);
    border-radius: 5px;
    padding: 20px;
    .feedback {
      display: block;
      color:var(--el-color-info-light-3);
      width: 100%;
      margin-top: 0.25rem;
      font-size: 1em;
      margin-bottom: 0px;
    }
  }
  
}

</style>
